/* eslint-disable indent */
import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { WidgetPrintHeader, NoRecordsPrintDiv } from '@mummssoftware/common-ui';
import { Container } from '../../components/layout/WrapperDiv';
import defaults from '../../translations/en.json';
import { Context } from '../../context';
import NotesListPrint from './NotesListPrint';

const ProgressNotesPrintDiv = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.common.white};
  @media print {
    page-break-after: always;
    color: ${(props) => props.theme.palette.common.black};
    background-color: ${(props) => props.theme.palette.common.white};
  }
`;
const PageBreak = styled.div`
  @media print {
    visibility: hidden;
  }
`;

type ProgressNotesViewPrintProps = {
  progressNotes?: mumms.progressNotebyEntity[];
  onWidgetReady?: (arg: mumms.ComponentName) => void;
};

const texts: NonNullable<mumms.JSONObject> = defaults;

export const ProgressNotesViewPrintComponent = (
  props: ProgressNotesViewPrintProps,
) => {
  const { progressNotes, printConfig } = useContext(Context);
  const { onWidgetReady } = props;

  // on component did mount
  useEffect(() => {
    // callback to alert dashboard the view is ready
    onWidgetReady && onWidgetReady('ProgressNotes' as mumms.ComponentName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <WidgetPrintHeader
        title={texts.printTitle as NonNullable<mumms.JSONObject>}
        printConfig={printConfig}
      />
      {Object.keys(progressNotes).length ? (
        <ProgressNotesPrintDiv>
          <NotesListPrint notes={progressNotes} />
        </ProgressNotesPrintDiv>
      ) : (
        <NoRecordsPrintDiv>
          <p> {texts.noProgressNotesDateRange}</p>
        </NoRecordsPrintDiv>
      )}
      <PageBreak />
    </Container>
  );
};

export default ProgressNotesViewPrintComponent;

/* eslint-disable indent */
import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Watermark } from '@mummssoftware/common-ui';
import { formatPostDate } from '@mummssoftware/utils/formatters';
import NoteComponent from './NoteComponent';
import { Context } from '../../context';
import { ProgressNoteWrapper } from '../../components/layout/WrapperDiv';

type ProgressNotesProps = {
  embedded?: boolean;
  smallWidget?: boolean;
  currentUser: mumms.User;
  patient?: mumms.patient;
  view?: 'readonly' | 'editable';
  online: boolean;
  agency?: string | null;
};

export const ProgressNotes = React.memo(
  ({ currentUser, smallWidget }: ProgressNotesProps) => {
    const {
      progressNotes,
      saveProgressNote,
      patientNumber,
      agency,
      watermark,
    } = useContext(Context);
    const {
      props: {
        dashboard: { smallWidgetHeight, fullSizeHeight },
      },
    } = useTheme();

    const saveNoteEventHandler = async (params: any) => {
      if (params.editableNote) {
        const { personNumber, siteId } = currentUser;
        const progressNoteData = {
          patientnumber: patientNumber,
          personnumber: personNumber,
          siteid: siteId,
          note: params.editableNote,
          noteddatetime: formatPostDate(new Date()),
        };
        await saveProgressNote(patientNumber, agency, progressNoteData, 'POST');
      }
    };

    return (
      <ProgressNoteWrapper
        id="note-list-wrapper"
        smallWidget={smallWidget}
        smallWidgetHeight={smallWidgetHeight}
        fullSizeHeight={fullSizeHeight}
      >
        {watermark && watermark.show ? (
          <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
        ) : null}
        <NoteComponent onChange={saveNoteEventHandler} notes={progressNotes} />
      </ProgressNoteWrapper>
    );
  },
);

export default ProgressNotes;

import React from 'react';
import CheckBoxes from '../CheckBoxes/Checkboxes';
import { formatMMDDYYhh } from '../../utils/utils';
import {
  NoteWrapper,
  NoteContentDiv,
  StyledSpan,
} from '../../components/layout/WrapperDiv';

type NoteProps = {
  note: mumms.progressNotebyEntity;
};

const Note = ({ note }: NoteProps) => (
  <NoteWrapper key={note.id} id="note-wrapper">
    <NoteContentDiv key={note.id} id="note-content">
      <span>
        <StyledSpan>
          {formatMMDDYYhh(note)} - {note.personname}{' '}
          {note.credentials === 'null' || note.credentials === null
            ? ''
            : note.credentials}
        </StyledSpan>
        {': '}
        {note.note}
      </span>
    </NoteContentDiv>
    <CheckBoxes note={note} />
  </NoteWrapper>
);

export default Note;

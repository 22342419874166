import moment from 'moment-timezone';

export const formatMMDDYYhh = (n: any) => {
  if (n.id !== -1) {
    return moment
      .utc(n.noteddatetime)
      .tz(n.sitetimezone)
      .format('MM-DD-YY hh:mm a');
    
  }
  return '';
};

import React, { useState, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { StyledTextField } from '../../components/layout/WrapperDiv';

type PhysCheckBoxProps = {
  note: mumms.progressNotebyEntity | null;
};

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: white;
  }
`;
const ITEM_HEIGHT = 38;

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: ITEM_HEIGHT * 10,
    width: 300,
    backgroundColor: `${(theme as any).palette.background.dark1}`,
  },
}));

const PhysCheckBox = ({ note }: PhysCheckBoxProps) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const handleChange = (event: any) => {
    setChecked((event.target as any).checked);
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <StyledCheckbox
        checked={checked}
        onChange={handleChange}
        color="default"
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          classes: { elevation8: classes.menuPaper },
        }}
      >
        <StyledTextField id='styled-text-field'>
          <TextField
            id="standard-full-width"
            label="Reason"
            style={{ margin: 8 }}
            placeholder="Reason"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </StyledTextField>
      </Menu>
    </Fragment>
  );
};

export default PhysCheckBox;

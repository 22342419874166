import React, { useEffect, useContext, useRef, Fragment } from 'react';
import Note from './Note';
import CheckBoxes from '../CheckBoxes/Checkboxes';
import {
  NoteListWrapper,
  TextArea,
  TextAreaWrapper,
  LabelWrapper,
  LabelDiv,
} from '../../components/layout/WrapperDiv';
import { Context } from '../../context';
import defaults from '../../translations/en.json';

type NoteListProps = {
  onChange: (arg: string) => void;
  notes: mumms.progressNotebyEntity[];
};

const NoteComponent = ({ onChange, notes }: NoteListProps) => {
  const { smallWidget } = useContext(Context);

  const handleChange = (e: any) => {
    if (e && e.target.value) {
      const noteContent: string = e.target.value;
      onChange(noteContent);
      e.target.value = null;
    }
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      if (elementRef.current) {
        const target: any = elementRef.current;
        target.parentNode.scrollTop = target.offsetTop;
      }
    }, [elementRef.current]);
    return <div ref={elementRef as any} />;
  };

  return (
    <Fragment>
      <NoteListWrapper id="notes-wrapper" smallWidget={smallWidget}>
        <LabelWrapper>
          <LabelDiv>{defaults.approval}</LabelDiv>
          <LabelDiv>{defaults.meds}</LabelDiv>
          <LabelDiv>{defaults.cpp}</LabelDiv>
        </LabelWrapper>
        {notes &&
          notes.length &&
          notes.map((note) => <Note note={note} key={note.id} />)}
        <AlwaysScrollToBottom />
      </NoteListWrapper>
      <TextAreaWrapper id="notes-text-area-wrapper">
        <TextArea
          name="editableNote"
          placeholder={defaults.textArea}
          rows={2}
          onBlur={handleChange}
        />
        <CheckBoxes top note={null} />
      </TextAreaWrapper>
    </Fragment>
  );
};

export default NoteComponent;

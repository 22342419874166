import React, { useState, useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { find } from 'lodash-es';
import { formatPostDate } from '@mummssoftware/utils/formatters';
import { Context } from '../../context';
import { MenuContentDiv } from '../../components/layout/WrapperDiv';
import { PCPP, MM } from '../../utils/constant';

type MedsMenuContentProps = {
  note: mumms.progressNotebyEntity;
  med?: mumms.Medication;
  carePlan?: mumms.patientCarePlanProblem;
};

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: white;
  }
`;

const MenuContent = ({ note, med, carePlan }: MedsMenuContentProps) => {
  const { saveProgressNote, patientNumber, agency, currentUser } = useContext(
    Context,
  );
  const entity = med ? MM : PCPP;
  const entitykey = med ? med.externalid : carePlan?.id;
  const isChecked = note.patientprogressnoteentitydto
    ? !!find(
        note.patientprogressnoteentitydto[entity],
        (x) => x.entitykey === entitykey,
      )
    : false;
  const [checked, setChecked] = useState(isChecked);
  const {
    id,
    noteddatetime,
    personnumber,
    patientprogressnoteentitydto,
  } = note;

  // eslint-disable-next-line consistent-return
  const handleChange = (event: any) => {
    if (isChecked) {
      return null;
    }
    setChecked((event.target as any).checked);
    const { personNumber, siteId } = currentUser;
    let newPatientprogressnoteentitydto: any;
    if (patientprogressnoteentitydto) {
      if (
        patientprogressnoteentitydto[MM] &&
        patientprogressnoteentitydto[PCPP]
      ) {
        newPatientprogressnoteentitydto = [
          ...(patientprogressnoteentitydto as any)[MM],
          ...(patientprogressnoteentitydto as any)[PCPP],
        ];
      } else if (patientprogressnoteentitydto[MM]) {
        newPatientprogressnoteentitydto = patientprogressnoteentitydto[MM];
      } else if (patientprogressnoteentitydto[PCPP]) {
        newPatientprogressnoteentitydto = patientprogressnoteentitydto[PCPP];
      }
    }

    const progressNoteData = {
      id,
      modifieddatetime: formatPostDate(new Date()),
      modifiedpersonnumber: personNumber,
      note: note.note,
      noteddatetime,
      patientnumber: patientNumber,
      personnumber,
      patientprogressnoteentitydto: patientprogressnoteentitydto
        ? [
            ...newPatientprogressnoteentitydto,
            {
              entitykey: med ? med?.externalid : carePlan?.id,
              entitytypeshortname: med ? 'MM' : 'PCPP',
              id: '',
            },
          ]
        : [
            {
              entitykey: med ? med?.externalid : carePlan?.id,
              entitytypeshortname: med ? 'MM' : 'PCPP',
              id: '',
            },
          ],
      siteid: siteId,
    };

    saveProgressNote(patientNumber, agency, progressNoteData, 'PUT');
  };

  return (
    <MenuContentDiv>
      <StyledCheckbox
        checked={checked}
        onChange={handleChange}
        color="default"
        inputProps={{ 'aria-label': 'controlled' }}
      />
      {med ? med.drugdescription : carePlan?.careplanproblemname}
    </MenuContentDiv>
  );
};

export default MenuContent;

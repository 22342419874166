import React, { useState, Fragment, useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import MenuContent from '../MenuContent/MenuContent';
import { Context } from '../../context';
import { StyledMenuSpan } from '../../components/layout/WrapperDiv';
import defaults from '../../translations/en.json';
import { whiteColor, greenColor, PCPP } from '../../utils/constant';

type CarePlanCheckBoxProps = {
  note: mumms.progressNotebyEntity | null;
};

const StyledCheckbox = styled(Checkbox)<{
  stylecolor?: string;
}>`
  &.MuiCheckbox-root {
    color: ${(props) => props.stylecolor};
  }
`;
const ITEM_HEIGHT = 38;

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: ITEM_HEIGHT * 10,
    width: 250,
    backgroundColor: `${(theme as any).palette.background.dark1}`,
  },
}));

const CarePlanCheckBox = ({ note }: CarePlanCheckBoxProps) => {
  const classes = useStyles();
  const { patientCarePlanProblem } = useContext(Context);
  const hasPCPP = !!(
    note &&
    note.patientprogressnoteentitydto &&
    note.patientprogressnoteentitydto[PCPP]
  );

  const [checked, setChecked] = useState(hasPCPP);
  const styleColor = hasPCPP ? greenColor : whiteColor;

  const handleChange = (event: any) => {
    setChecked((event.target as any).checked);
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <StyledCheckbox
        stylecolor={styleColor}
        checked={hasPCPP ? true : checked}
        onChange={handleChange}
        color="default"
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          classes: { elevation8: classes.menuPaper },
        }}
      >
        <div>
          {patientCarePlanProblem && patientCarePlanProblem.length ? (
            patientCarePlanProblem.map((cpp: mumms.patientCarePlanProblem) => (
              <MenuContent
                note={note as mumms.progressNotebyEntity}
                carePlan={cpp}
                key={cpp.id}
              />
            ))
          ) : (
            <StyledMenuSpan>{defaults.noCP}</StyledMenuSpan>
          )}
        </div>
      </Menu>
    </Fragment>
  );
};

export default CarePlanCheckBox;

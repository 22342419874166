import React from 'react';
import MedsCheckBox from './MedsCheckBox';
import CarePlanCheckBox from './CarePlanCheckBox';
import PhysCheckBox from './PhysCheckBox';
import { CheckBoxesDiv } from '../../components/layout/WrapperDiv';

type CheckBoxesProps = {
  note: mumms.progressNotebyEntity | null;
  top?: boolean;
};

const CheckBoxes = ({ note, top }: CheckBoxesProps) => (
  <CheckBoxesDiv id="checkbox-content" top={top}>
    <PhysCheckBox note={note} />
    <MedsCheckBox note={note} />
    <CarePlanCheckBox note={note} />
  </CheckBoxesDiv>
);

export default CheckBoxes;

import React, { useState, Fragment, useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import MenuContent from '../MenuContent/MenuContent';
import { Context } from '../../context';
import { StyledMenuSpan } from '../../components/layout/WrapperDiv';
import defaults from '../../translations/en.json';
import { whiteColor, greenColor, MM } from '../../utils/constant';

type MedsCheckBoxProps = {
  note: mumms.progressNotebyEntity | null;
};

const StyledCheckbox = styled(Checkbox)<{
  stylecolor?: string;
}>`
  &.MuiCheckbox-root {
    color: ${(props) => props.stylecolor};
  }
`;
const ITEM_HEIGHT = 38;

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: ITEM_HEIGHT * 10,
    width: 300,
    backgroundColor: `${(theme as any).palette.background.dark1}`,
  },
}));

const MedsCheckBox = ({ note }: MedsCheckBoxProps) => {
  const classes = useStyles();
  const { medications } = useContext(Context);
  const hasMeds = !!(
    note &&
    note.patientprogressnoteentitydto &&
    note.patientprogressnoteentitydto[MM]
  );

  const [checked, setChecked] = useState(hasMeds);
  const styleColor = hasMeds ? greenColor : whiteColor;

  const handleChange = (event: any) => {
    setChecked((event.target as any).checked);
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <StyledCheckbox
        stylecolor={styleColor}
        checked={hasMeds ? true : checked}
        onChange={handleChange}
        color="default"
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          classes: { elevation8: classes.menuPaper },
        }}
      >
        <div>
          {medications && medications.length ? (
            medications.map((med: mumms.Medication) => (
              <MenuContent
                key={med.id}
                note={note as mumms.progressNotebyEntity}
                med={med}
              />
            ))
          ) : (
            <StyledMenuSpan>{defaults.noMeds}</StyledMenuSpan>
          )}
        </div>
      </Menu>
    </Fragment>
  );
};

export default MedsCheckBox;
